@import (reference) "../theme.less";

[data-site="dakkapeloffertes_nl"] {

  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 28px;
    }
  }

  .hero {
    background-position: 50% 35%;
  }

}
