@import (reference) 'theme.less';

#faq {

  margin-bottom: 50px;

  .content {
    display: none;
    -webkit-transition: max-height 1s ease-out;
    -o-transition: max-height 1s ease-out;
    transition: max-height 1s ease-out;

    ul {
      list-style-type: disc;
      margin-bottom: 10px;
    }
  }

  .content
  .content h4 {
    font-size: 16px;
  }

  .faq-items {
    padding: 0;
    margin-bottom: 25px;
  }

  .group h3 {
    text-decoration: underline;
  }

  .item {
    display: block;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    &:first-child {
      border-top: 1px solid #e0e0e0;
    }

    &.active {
      .content {
        display: block;
        padding-right: 60px;
        padding-bottom: 6px;
      }

      .title {
        font-weight: bold;
      }

      .title a:after {
        -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }

    .title {
      font-weight: normal;
      cursor: pointer;
      margin: 0;
      padding: 0;
      font-size: 16px;
      position: relative;

      a {
        display: block;
        color: #494949;
        padding: 15px 10px 15px 0;

        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -6px;
          background-size: 100%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 7px 9px 7px;
          border-color: transparent transparent #1aad4a transparent;
          -webkit-transition: all 0.4s ease-out;
          -o-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
        }

        @media (min-width: @screen-sm) {
          font-size: 18px;
          &:after {
            right: 40px;
          }
        }
      }
    }
  }
}

