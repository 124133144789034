/** Slick carousel arrow styling **/
.slick-slide {
  margin: 0 10px;
}

.slick-slider {
  .slider-nav {
    top: 35%;
    width: 48px;
    height: 48px;
    position: absolute;
    z-index: 9000;
    &.nav-right {
      background-image: url('/build/img/seo-components/carousel-nav-left.png');
      right: 15px;
    }

    &.nav-left {
      background-image: url('/build/img/seo-components/carousel-nav-right.png');
      left: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
