@import 'theme.less';

@footer-height: 385px;

@media (min-width: @screen-md) {
  /* Lastly, apply responsive CSS fixes as necessary */
  @media (max-width: 767px) {
    .footer-wrap {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#wrap {
  flex-grow: 1;
  flex-shrink: 0;
}

.footer-wrap {
  flex-shrink: 0;
}
