@gray-150: #fafafa;
@gray-100: #F5F7F7;

.bg-gray-100 {
  background-color: @gray-100 !important;
}

.bg-gray-150 {
  background-color: @gray-150 !important;
}

.bg-white {
  background-color: @white !important;
}

.bg-lg-gray-150 {
  @media (min-width: @screen-lg) {
    background-color: @gray-150 !important;
  }
}

.bg-green-light {
  background-color: #DFF0D8 !important;
}

.bg-lg-white {
  @media (min-width: @screen-lg) {
    background-color: @white !important;
  }
}
