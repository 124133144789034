@import (reference) "../theme.less";

[data-site="us_painter_24_com"] {

  .footer-image {
     background: url("/build/img/footer/painter.jpg") no-repeat;
  }

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 24px;
      }
    }

  }

}
