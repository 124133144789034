.article-holder {
  position: relative;
  max-width: 770px;
  margin: 0 auto;

  h4 {
    color: #565a5c;
    font-size: 16px;
  }
  p {
    margin: 15px 0px 25px;
    color: #565a5c;
    line-height: 1.72;
    font-size: 18px;

    a {
      color: rgb(2, 66, 128);
      &:hover {
        color: rgb(65, 99, 176);
      }
    }
    span {
      margin: 0px;
    }
  }
  ul {
    margin-bottom: 20px;
    li {
      color: #565a5c;
      margin-bottom: 5px;
      line-height: 24px;
      a {
        color: rgb(2, 66, 128);
        &:hover {
          color: rgb(97, 137, 176);
        }
      }
    }
  }
  ol {
    margin-bottom: 20px;
    li {
      color: #565a5c;
      margin-bottom: 15px;
      line-height: 24px;
      a {
        color: rgb(2, 66, 128);
        &:hover {
          color: rgb(97, 137, 176);
        }
      }
      span {
        margin: 0px;
        display: block;
      }
    }
  }
}

.intro {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.55;
  text-align: center;
  color: #575a5c;
  background: #fafafa;
  margin-bottom: 25px;

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #575a5c;
  }
}


.article-holder {
  .table-response {
    margin: 40px 0;
  }

  .tablepress {
    border: #979797 1px solid;
    font-size: 18px;
    color: #565a5c;

    tfoot, thead {
      th {
        padding: 10px 21px;
        background: none;
        color: #565a5c;
        font-weight: normal;
        border-right: 1px solid #979797;
        border-bottom: 1px solid #979797;
      }
    }

    .odd {
      td {
        background: none;
      }

    }
    tbody {
      td {
        border-right: #979797 1px solid;
        border-bottom: 1px solid #979797;
        padding: 10px 21px;
      }
    }
  }

  img.alignleft {
    margin-right: 32px;
  }

  img.alignright {
    margin-left: 32px;
  }
}
