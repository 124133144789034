@import (reference) "../theme.less";

[data-site="airco_offertes_com"] {

.hero {
    /**IE 8 **/
    background-size: cover;
  }

  @media (max-width: @screen-xs-max) {
      .main-content::before {
        background-size: cover;
      }
  }
}
