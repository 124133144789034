@import '../variables.less';
/* Search radar and success message experiment styles */
#b2c-form > form {
  position: relative;
  #loadingState {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    padding-top: 64px;
    background-color: #fafafa;

    @media (min-width: @screen-sm) {
      right: -15px;
      left: -15px;
    }

    .heading {
      font-size: 18px;
      font-weight: bold;
    }

    .message {
      font-size: 14px;
    }
  }
}

#loadingStateSuccessMessage {
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: @slate-gray;
  margin: -10px -20px 12px;
  padding: 16px 0;

  &.home-loading-message {
    padding: 12px 16px;
    line-height: 22px;
    .check-mark {
      right: 16px
    }
  }

  .check-mark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }

  span {
    color: @primary-color;
  }

  span#successMessageText {
    color: @slate-gray;
  }


  &:not(.home-loading-message) {
    @media (min-width: @screen-sm) {
      margin: -20px 0 12px;
      text-align: center;
      padding: 28px 0;
      font-size: 20px;
    }
  }

}

#loadingStateEncourageMessage {
  margin-top: 8px;
  margin-bottom: 12px;
}

/* spacing for the form */
#b2c-form{
  .sd-mb-2_5 {
    margin-bottom: 40px;
  }
  .sd-mb-1 {
    margin-bottom: 16px;
  }
}
