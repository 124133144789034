@import '../../../../theme.less';

.clearable-wrap {
  position: relative;
  overflow: hidden;

  .ng-pending + .clearable-btn {
    opacity: 0;
  }
  
  .clearable-btn {
    top: 25px;
    right: 8px;
    position: absolute;
    cursor: pointer;
    text-decoration: none;

    @size: 16px;
    font-size: @size;
    line-height: @size;
    width:  @size;
    height: @size;
    border-radius: @size;

    -webkit-transition: all 250ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transition:         all 250ms cubic-bezier(0.68, -0.55, 0.27, 1.55);

    &.clearable-hidden {
      opacity: 0;
    }
  }
}

.placeholder-container {
  position: relative;

  .placeholder {
    position: absolute;
    right: 20px;
    top: 11px;
    font-size: 13px;
    color: #b8b8b8;
    pointer-events: none;

    -webkit-transition: all 250ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transition:         all 250ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }

  @media(min-width: 768px) {
    .label-placeholder {
      display: none;
    }
  }

  .clearable-wrap + .placeholder {
    top: 8px;
    right: 29px;
  }

  .placeholder-hidden {
    opacity: 0;
  }
}
