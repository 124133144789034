@import (reference) 'theme.less';
@import "variables.less";

@rating-bad-color: @danger;
@rating-poor-color: @warning;
@rating-fair-color: @primary;
@rating-good-color: @primary;
@rating-excellent-color: @primary;


.already-submitted {
  margin-top: 100px;
}

/**
  Company logo
 */
.logo-bg {
  position: relative;

  .company-image {
    background: linear-gradient(to right, #1aad4a, #1ab84b);
    position: absolute;
    width: 100%;
    height: 240px;
    overflow: hidden;
    background-size: 100% 240px;
  }
}

.thank-you-container {
  margin-top: 130px;
  margin-bottom: 130px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  padding: 94px 31px 102px 31px;
  border: solid 1px #d7d7d7;

  .title {
    font-size: 28px;
    font-weight: bold;
    color: #4d4d4d;
  }

  .description {
    font-size: 18px;
    color: #4d4d4d;
  }
}

/**
  Review form
 */
.review-form-container {
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  font-size: 16px;

  label {
    font-weight: bold;

    .option {
      margin-bottom: 10px;
      font-weight: normal;

      &:hover {
        cursor: pointer;
      }
    }

    &.margin-0 {
      margin: 0px;
    }
  }

  p.sublabel {
    font-size: 14px;
    color: #adb5bd;
  }

  .form-title {
    padding: 25px 10px;
    border-bottom: 1px solid #d7d7d7;
    font-size: 16px;
    font-weight: 600;

    @media (min-width: @screen-md) {
      padding: 25px 20px;
      font-size: 24px;
    }
  }

  button[type="submit"] {
    width: 100%;

    @media (min-width: @screen-md) {
      padding: 7px 35px;
      width: auto;
    }

    &.loading {
      background: url('/build/img/ajax-loader.gif') 95% center no-repeat #038e37;
    }
  }

  .form-body {
    padding: 25px 10px;

    @media (min-width: @screen-md) {
      padding: 25px 20px;
    }
  }

  @media (min-width: @screen-lg) {
    #title_experience {
      width: 80%;
    }
  }

  /**
  Star rating component
  */
  .ratings {
    margin: 30px 0;

    @media (min-width: @screen-md) {
      margin: 30px 0 50px 0;
    }

    .total-rating-disabled {
      color: #eaeaea;
    }

    .control-label {
      text-align: left;
    }

    .rating-label {
      font-weight: bold;
      font-size: 16px;
    }

    .rating-description {
      @media (min-width: @screen-md) {
        display: inline-block;
        float: right;
        line-height: 35px;
      }
    }

    //removes outline (blue line in chrome) from rating (star) elements
    * {
      &:focus {
        outline: none;
      }
    }

    i {
      font-size: 24px;
      background-color: #eaeaea;
      margin-right: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    .star-rating-status(@color) {
      color: @color;

      i.active {
        background-color: @color;
      }
    }

    .rating-container {
      padding: 20px 0;
      border-bottom: 1px solid #d7d7d7;
      border-bottom: 1px solid #d7d7d7;

      .form-group {
        margin-bottom: 0;
      }

      &.first {
        border-top: 1px solid #d7d7d7;
      }

      .rating {
        @media (min-width: @screen-md) {
          display: inline;
        }

        &-bad {
          .star-rating-status(@rating-bad-color);
        }
        &-poor {
          .star-rating-status(@rating-poor-color);
        }
        &-fair {
          .star-rating-status(@rating-fair-color);
        }
        &-good {
          .star-rating-status(@rating-good-color);
        }
        &-excellent {
          .star-rating-status(@rating-excellent-color);
        }
      }
    }

    .glyphicon-star {
      border-radius: 50%;
      padding: 8px;
      color: white;
    }
  }

  /**
  Review rating box
  */
  .review-rating-box(@color) {
    color: @color;
    border-color: @color;
  }

  .review-rating {
    display: inline-block;

    width: 60px;
    height: 40px;

    margin: 0 auto;;
    vertical-align: bottom;
    line-height: 40px;
    text-align: center;
    border: 2px solid #d7d7d7;
    border-radius: 2px;

    font-size: 24px;
    font-weight: 600;

    &:hover {
      cursor: default;
    }

    &.rating {
      &-bad {
        .review-rating-box(@rating-bad-color)
      }
      &-poor {
        .review-rating-box(@rating-poor-color)
      }
      &-fair {
        .review-rating-box(@rating-fair-color)
      }
      &-good {
        .review-rating-box(@rating-good-color)
      }
      &-excellent {
        .review-rating-box(@rating-excellent-color)
      }
    }
  }

  .review-input-error {
    border: 1px solid @danger;
    margin-bottom: 0;
  }

  .text-error {
    color: @danger;
  }

  .input-message {
    height: 25px;
  }
}

.company-rating-very-good {
  &.rating-box {
    background-color: @primary-light;
  }
  .rating {
    font-weight: @font-weight-bold;
    color: @primary;
  }
}
