#company-profile-public-carousel::after {
  background: #627881;
}

#carousel-close-button, #carousel-prev, #carousel-next {
  border-color: #d2dadd;

  img {
    filter: invert(56%) sepia(41%) saturate(6539%) hue-rotate(110deg) brightness(97%) contrast(80%);
  }
}
