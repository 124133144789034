.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-md-row {
  @media (min-width: @screen-md) {
    flex-direction: row !important;
  }
}

.flex-md-column {
  @media (min-width: @screen-md) {
    flex-direction: column;
  }
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-flex-end{
  justify-content: flex-end !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.justify-content-md-end {
  @media (min-width: @screen-md) {
    justify-content: end !important;
  }
}

.align-items-center {
  align-items: center !important;
}

.flex-sm-row {
  @media (min-width: @screen-sm) {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
}

.flex-sm-column {
  @media (min-width: @screen-sm) {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.flex-even {
  flex: 1;
}

.order-md-1 {
  @media (min-width: @screen-md) {
    order: 1;
  }
}

.order-md-2 {
  @media (min-width: @screen-md) {
    order: 2;
  }
}

