.font-weight-bold {
  font-weight: bold !important;
}

.text-primary {
  color: @primary-color;
}

.text-slate-gray {
  color: @slate-gray;
}

.smaller {
  font-size: 14px;
}

.p-large {
  font-size: 16px;
  @media (min-width: @screen-sm) {
    font-size: 20px;
  }
}

.text-sm-center {
  @media (max-width: @screen-sm) {
    text-align: center !important;
  }
}
