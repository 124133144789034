@import (reference) "../theme.less";

[data-site="camerabeveiligingoffertes_nl"] {

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 28px;
      }
    }
  }

  @media (max-width: @screen-xs) {
    .why h2 {
      font-size: 20px;
    }
  }
}

