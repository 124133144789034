@import 'theme.less';
@import 'variables.less';

.next-steps {
  h2 {
    text-align: center;
    @media (min-width: @screen-md) {
      margin-top: 64px;
      margin-bottom: 16px;
    }
  }

  p {
    margin-bottom: 0;
    text-align: center;
    font-size: 18px;
    color: #5e5f61;
  }

  .next-steps-list {
    padding: 20px;
    background: #fcfcfc;
    border: 1px solid #eaeaea;
    margin-bottom: 0;
  }
}

.no-companies-found {
  h2 {
    text-align: center;
    @media (min-width: @screen-md) {
      margin-top: 64px;
      margin-bottom: 24px;
    }
  }

  p {
    text-align: center;
    margin-bottom: 24px;
  }
}

.companies-faq {
  margin-bottom: 20px; // for total of 60px from footer

  h3 {
    margin-top: 0;
    line-height: 1.5;
  }
  p {
    font-size: 16px;
    margin: 0 0 8px 0;
  }
}

.loading-companies {
  h2 {
    text-align: center;
    @media (min-width: @screen-md) {
      margin-top: 64px;
      margin-bottom: 24px;
    }
  }

  p {
    text-align: center;
    &:last-child {
      margin-bottom: 56px;
    }
  }
}

.selected-companies-header {
  h3 {
    text-align: center;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
  }
}

.selected-companies-two {
  .profile-panel {
    border-radius: 4px;
    border: 1px solid #EAEAEA;
    padding: 20px;
    margin-top: 16px;

    @media (min-width: @screen-md) {
      padding: 30px;
    }

      h1, h2, h3, h4, h5, h6 {
      line-height: 1.5;
      font-weight: @font-weight-bold;
      color: @slate-gray;

      a, a:hover {
        color: @slate-gray;
      }
    }
  }

  .btn-sm {
    padding: 5px 10px !important;
  }

  .link-secondary {
    color: @link-secondary-color;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

    &:hover {
      color: @link-secondary-color;
      text-decoration: @link-hover-decoration;
    }

    &:active {
      font-weight: @font-weight-bold;
    }
  }

  .company-profile-details {
    display: flex;

    .no-reviews,
    .reviews-count {
      font-size: 16px;
    }

    @media (min-width: @screen-md) {
      justify-content: space-between;
    }

    .rating-label {
      display: inline-block;
      padding: 2px 10px;
      font-size: 16px;
      font-weight: 400;
      color: #627881;
      border-radius: 2px;
    }

    .company-rating-unknown {
      &.rating-box {
        background-color: @gray-300;
      }
      .rating {
        color: @gray-700;
      }
    }

    .company-rating-very-bad,
    .company-rating-bad {
      &.rating-box {
        background-color: @danger-light;
      }
      .rating {
        font-weight: @font-weight-bold;
        color: @danger;
      }
    }

    .company-rating-medium {
      &.rating-box {
        background-color: @warning-light;
      }
      .rating {
        font-weight: @font-weight-bold;
        color: @warning;
      }
    }
    .company-rating-good {
      &.rating-box {
        background-color: @primary-light;
      }
      .rating {
        font-weight: @font-weight-bold;
        color: @primary;
      }
    }
    .company-rating-very-good {
      &.rating-box {
        background-color: @primary-light;
      }
      .rating {
        font-weight: @font-weight-bold;
        color: @primary;
      }
    }

    .company-logo-container-wrapper {
      max-width: 270px;

      .company-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 84px;
        img {
          max-width: 100%;
          max-height: 56px;
        }
        padding: 14px 7px;
        @media (min-width: @screen-md) {
          height: 130px;
          padding: 20px 18px;
          img {
            max-height: 80px;
          }
        }
        border: 1px solid #EAEAEA;
      }

    }
  }

  .p-small {
    font-size: 14px;
  }
}
