.modal {
  text-align: center;
  padding: 0!important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;

  @media (max-width: 552px) {
    height: 39%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;

  .modal-content {
    padding: 5px;

    @media (min-width: @screen-md) {
      padding: 35px;
    }

    .modal-close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
    .modal-header,
    .modal-footer {
      border-color: transparent;
    }

    .modal-header {
      padding-right: 43px;
      padding-bottom: 11px;

      .modal-title {
        color: @charcoal-grey;
      }

      h3.modal-title {
        font-size: 20px;
      }

      @media (min-width: @screen-md) {
        padding-right: 15px;
        padding-bottom: 15px;
      }
    }

    .modal-footer {
      padding-top: 7px;

      @media (min-width: @screen-md) {
        padding-top: 15px;
      }
    }

    .modal-body {
      padding: 0 15px;

      p {
        font-size: 16px;

        &.text-light-gray {
          color: @battleship-grey;
        }
      }
    }
  }
}
