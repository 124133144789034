@import (reference) "../theme.less";

[data-site="loodgieteroffertes_nl"] {
  .hero {
  }
  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 26px;
    }
  }
}

