.siteName {
  text-align: center;
  padding: 48px 0;

  h2 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  p {
    big {
      color: #565a5c;
      font-size: 20px;
      display: inline-block;
      margin: 7px 0 0 0;
    }
  }

  .article-title {
    color: #5e5f61;
    padding: 20px 0;
    font-size: 16px;
  }

  .overview-articles {
    list-style-type: none;
    list-style-position: inside;
    margin: 0;

    li {
      margin-bottom: 45px;
      text-align: left;
    }
  }
}
