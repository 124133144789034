@thumb-border-color: #e8e8e8;
@thumb-color: #fff;

.post-thumb {
  height: 100%;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid @thumb-border-color;

  &:hover,
  &:active {
    border-color: darken(@thumb-border-color, 10%);

    img {
      opacity: 0.8;
    }

    a {
      text-decoration: none;
    }
  }

  a {
    color: #616265;
  }

  .entry-title {
    margin: 15px 0 5px 0;
  }

  img {
    height: 220px;
    max-width: 100%;
    object-fit: cover;
  }

  .post-excerpt {
    font-size: 16px;
  }

  .post-content {
    padding: 0 30px 5px;
  }

  .thumb-container {
    position: relative;
    border-bottom: 3px solid @primary-color;
    background-color: #fff;
  }

  &:hover .darken-on-hover > a > img {
    opacity: 0.9;
  }

  .thumb-link {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    background-color: @primary-color;
    color: @thumb-color;
  }
}

