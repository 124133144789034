@import (reference) "../theme.less";

[data-site="metselaaroffertes_nl"] {
  .hero {
    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 27px;
      }
    }

  }
 }
