@import "variables.less";

@screen-xs: 480px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1200px;

@screen-xs-max:              (@screen-sm - 1);
@screen-sm-max:              (@screen-md - 1);
@screen-md-max:              (@screen-lg - 1);

body {
  font-family: 'ProximaNova', 'Montserrat', sans-serif;
  color: @gray-600;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1 {
  font-size: 32px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 16px;
  font-weight: bold;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 16px;
  font-weight: bold;
}

h6 {
  font-size: 16px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 54px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 16px;
  }
}

.upper {
  text-transform: uppercase;
}

.button {
  padding: 10px 16px;
}

.btn-default {
  border: 1px solid @green;
  color: @green;
  font-size: 16px;
}

button, a.button {
  border: 1px solid @green;
  background: @green;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;

  &:hover {
    background: @green-dark;
  }
  &:disabled {
    color: @gray-400;
    background: @gray-200;
  }

  &.inverse {
    color: @green;
    border: 1px solid @green;
    background: #ffffff;

    &:hover {
      background: @green;
      color: #ffffff;
      border: 1px solid @green;
    }
    &:disabled {
      color: #9e9e9e;
      background: #ededed;
      border: 1px solid #c1c1c1;
    }
  }

  &.form-control:hover {
    background: #ededed;
  }
}

a.button {
  padding: 5px 40px;

  &:hover {
    text-decoration: none;
  }
}

