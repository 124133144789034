@import "variables.less";
.header-container {
  box-shadow: none;
  border-bottom: 1px solid @home-gray-300;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: #fff;
}

.header {
  height: 70px;
  display: flex;
  align-items: center;

  .logo svg {
    color: #00af1a;
  }

  .boast {
    div {
      font-size: 15px;
      float: left;
      margin-right: 25px;
      line-height: 35px;

      .million-moves {
        width: 33px;
      }

      .checks {

        svg {
          height: 27px;
          width: 21px;
        }

        width: 15px;
      }

      .reviews {
        width: 22px;
      }

      .professional {
        width: 17px;
      }

      svg {
        vertical-align: middle;
        height: 20px;
        margin-right: 9px;
      }

    }

    > div:last-child {
      margin-right: 0;
    }
  }
}

.header-container.header-trust {
  display: block;

  .sd-container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 13px;
    padding-right: 13px;
  }

  @media (min-width: @screen-sm) {
    .sd-container {
      width: 720px;
    }
  }
  @media (min-width: @screen-md) {
    .sd-container {
      width: 940px;
    }
  }
  @media (min-width: @screen-lg) {
    .sd-container {
      width: auto !important;
      max-width: 1260px !important;
    }
  }
}

.header-container.header-trust .sd-container .header {

  .boast {
    div {
      margin-right: 5px;

    }

    .trust-bar-head {
      margin-right: 5px;
      font-size: 14px;
      font-weight: bold;
      display: block;

      .trust-component {
        margin-right: 13px;
        display: block;

        > svg {
          width: auto;
          height: 28px;

          text {
            font-size: 14px;
            font-weight: bold;
            fill: @slate-gray;
          }
        }

      }
    }
  }
}
