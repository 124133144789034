.border-left-md {
  @media (min-width: @screen-md) {
    border-left: 1px solid #D1D4D6 !important;
  }
}

.border-top {
  border-top: 1px solid #D1D4D6 !important;
}

.border-top-md-0 {
  @media (min-width: @screen-md) {
    border-top: 0 !important;
  }
}

.border-gray-300 {
  border: 1px solid @gray-300 !important;
}

.border-none {
  border-style: none !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}
