.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-sm-block {
  @media (min-width: @screen-sm) {
    display: block !important;
  }
}

.d-sm-none {
  @media (min-width: @screen-sm) {
    display: none !important;
  }
}

.d-md-block {
  @media (min-width: @screen-md) {
    display: block !important;
  }
}

.d-md-none {
  @media (min-width: @screen-md) {
    display: none !important;
  }
}

.visible-lg-only {
  display: none;

  @media (min-width: @screen-lg) {
    display: block;
  }
}

.d-md-block {
  @media (min-width: @screen-md) {
    display: block !important;
  }
}

.d-md-none {
  @media (min-width: @screen-md) {
    display: none !important;
  }
}

.d-sm-flex {
  @media (min-width: @screen-sm) {
    display: flex !important;
  }
}

.d-md-flex {
  @media (min-width: @screen-md) {
    display: flex !important;
  }
}
.d-lg-flex {
  @media (min-width: @screen-lg) {
    display: flex !important;
  }
}

.sd-py-0_25 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.sd-py-0_75 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.sd-py-1 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.sd-py-1_25 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.sd-py-1_5 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
}

.sd-px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.sd-px-1 {
    padding-left: 16px !important;
    padding-right: 16px !important;

}

.sd-px-0_75 {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.sd-p-0_25 {
  padding: 4px !important;
}

.sd-p-0_5 {
  padding: 8px !important;
}

.sd-p-1 {
  padding: 16px !important;
}

.sd-mr-0_25 {
  margin-right: 4px !important;
}

.sd-ml-2 {
  margin-left: 32px !important;
}

.sd-ml-md-2 {
  @media (min-width: @screen-md) {
    margin-left: 32px !important;
  }
}

.sd-ml-auto {
  margin-left: auto !important;
}

.sd-mb-2 {
  margin-bottom: 2rem !important;
}
.sd-mt-2 {
  margin-top: 2rem !important;
}

.sd-my-0_5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.sd-m-0_25 {
  margin: 0.25rem !important;
}

.sd-m-0_5 {
  margin: 0.5rem !important;
}

.sd-mx-sm-2 {
  @media (min-width: @screen-sm) {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}

.sd-mt-4 {
  margin-top: 4rem !important;
}

.sd-p-md-1 {
  @media (min-width: @screen-md) {
    padding: 16px !important;
  }
}
