@import (reference) "../theme.less";

[data-site="alarme24_ch"] {
  .hero {
    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 22px;
      }
    }
  }
}
