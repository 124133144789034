@import (reference) "../theme.less";

[data-site="tegelzetteroffertes_nl"] {

  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 28px;
    }
  }

  .hero {
    background-position: 80% 10%;
  }
}
