.affix {
  top: auto;
  width: 100%;
  bottom: 0;
  z-index: 9999;

  #sticky-form {
    display: block;
  }
}

body.sd-form-open #sticky-form {
  display: none;
}

#sticky-form {
  color: #fff;
  background-color: #E1E1E1;
  display: none;
  padding: 20px 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  @media(min-width: @screen-sm) {
    background-color: #1aad4a;
  }

  span {
    font-weight: bold;
  }

  .close-icon {
    background: url('/build/img/seo-components/icon-close.png') 0 0 no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -7.5px;
    right: 10px;
    bottom: 0;
    color: #fff;
    width: 15px;
    height: 15px;

    @media(min-width: @screen-md) {
      right: 30px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .cta {
    height: 46px;
    display: inline-block;

    @media(min-width: @screen-sm) {
      margin-left: 10px;
    }

    @media(min-width: @screen-md) {
      margin-left: 30px;
    }

    .postcode {
      width: 120px;

      @media(min-width: @screen-md) {
        width: 170px;
      }
    }

    input {
      height: 100%;
      border: none;
      vertical-align: middle;
      text-transform: uppercase;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        text-transform: none;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        text-transform: none;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        text-transform: none;
      }
      &:-moz-placeholder { /* Firefox 18- */
        text-transform: none;
      }
    }

    input[type="text"] {
      padding: 6px 0 6px 12px;
      color: #565a5c;
      box-shadow: none;

      &:hover, &:focus, &:active {
        border-color: #024280;
      }
    }

    input[type="submit"] {
      background: #1aad4a;
      padding: 8px 16px;
      font-weight: bold;
      text-align: center;
      font-size: 16px;

      &:hover, &:focus, &:active {
        background: #038e37;
        outline: none;
      }

      @media(min-width: @screen-sm) {
        color: #1aad4a;
        background: #fff;
        font-size: 25px;

        &:hover, &:focus, &:active {
          background: #fff;
        }
      }
    }
  }
}
