@import (reference) "../theme.less";

[data-site="stoffeerderoffertes_nl"] {

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 28px;
      }
    }

  }

}

