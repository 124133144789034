@charset "utf-8";
@import "theme";
@import "libraries/skydreams/clearable/less/sd-clearable";
@import "form/bullet-progress";
@import "form/form";
@import "form/buttons";
@import "form/input-clearable";
@import "form/pretty-checkbox";
@import "form/pretty-datepicker";
@import "form/pretty-radios";
@import "form/pretty-file";
@import "form/typeahead";
@import "font-proxima-nova";
@import "form/pretty-timepicker";
@import "form/radio-boxed";
@import "utilities/spacing";
@import "modal/modal";

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.col-centered {
  float: none !important;
  margin: 0 auto;
}

.ng-fade {
  transition: 0.5s linear all;
  opacity: 1;
}
.ng-fade.ng-hide {
  opacity: 0;
}
