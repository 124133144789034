@import (reference) "../theme.less";

[data-site="pintor24_es"] {


  .footer-image {
    background: url("/build/img/footer/painter.jpg") no-repeat;
  }

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 23px;
      }
    }

  }

  @media (max-width: @screen-xs-max) {
    .step-1 {
      width: 95px;
    }
  }
}
