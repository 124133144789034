@import (reference) "../theme.less";

[data-site="hovenier_offerte_nl"] {


  .footer-image {
    background: url("/build/img/footer/gardener.png") no-repeat;
    height: 280px;
  }

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 31px;
      }
    }
    background-position: 50% 20%;
  }

}
