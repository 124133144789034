.timepicker {
  select {
    max-width: 50%;
    border-right-width: 0;
  }

  .input-group-addon {
    background-color: #ffffff;
    border-color: #adadad;
    box-shadow: none;
    background: none;
  }

  .input-group-addon:last-child {
    border-left: 1px #adadad solid;
  }

  .has-error .input-group-addon:last-child {
    border-left-color: #adadad;
  }

  i {
    color: #5e5f61;
  }
  .text-info {
    color: #36B760;
  }
}
