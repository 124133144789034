@import (reference) "../theme.less";

[data-site="getaroofer_co_uk"] {

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 26px;
      }
    }

    /**IE 8 **/
    background-size: cover;
  }

  @media (max-width: @screen-xs-max) {
    .main-content::before {
      background-size: cover;
    }
  }
}
