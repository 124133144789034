@screen-xs: 480px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1200px;

@screen-xs-max:              (@screen-sm - 1);
@screen-sm-max:              (@screen-md - 1);
@screen-md-max:              (@screen-lg - 1);

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'ProximaNova', 'Montserrat', sans-serif;
  color: #5e5f61;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1 {
  font-size: 32px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 16px;
  font-weight: bold;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 16px;
  font-weight: bold;
}

h6 {
  font-size: 16px;
}

@media (min-width: 768px) {
  h1 {
    font-size: 54px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 16px;
  }
}

.upper {
  text-transform: uppercase;
}

.button {
  padding: 10px 0;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

button, a.button {
  border: 1px solid #1aad4a;
  background: #1aad4a;
  border-radius: 4px;
  color: #ffffff;

  &:hover {
    background: #038e37;
  }
  &:disabled {
    color: #9e9e9e;
    background: #ededed;
  }

  &.inverse {
    color: #1aad4a;
    border: 1px solid #1aad4a;
    background: #ffffff;

    &:hover {
      background: #1aad4a;
      color: #ffffff;
      border: 1px solid #1aad4a;
    }
    &:disabled {
      color: #9e9e9e;
      background: #ededed;
      border: 1px solid #c1c1c1;
    }
  }

  &.form-control:hover {
    background: #ededed;
  }
}

a.button {
  padding: 5px 40px;

  &:hover {
    text-decoration: none;
  }
}

.row-dark {
  background: #fafafa;
}

.logo svg {
  max-width: 100%;
  height: 40px;
  padding: 0;
}

.error-block {
  color: #d9534f;
  text-align: center;
  border-radius: 4px;
  background-color: rgba(217, 83, 78, 0.1);
  border: solid 1px rgba(217, 83, 78, 0.35);
  font-size: 15px;
  font-weight: 600;
  line-height: 1.33;
}
