@import (reference) "../theme.less";

[data-site="alarm_365_ch"] {
  .hero {
    background: url("/build/img/sites/alarm-365.ch/hero.jpg") no-repeat center;

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 22px;
      }
    }
  }
 }
