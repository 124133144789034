@import (reference) "../theme.less";

[data-site="gevelreinigeroffertes_nl"] {

  .footer-image {
    background: url("/build/img/footer/handyman.jpg") no-repeat;
  }

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 31px;
      }
    }
    background-position: 50% 20%;
  }
}

