@import (reference) "../theme.less";

[data-site="architectoffertes_be"] {

  .footer-image {
    background: url("/build/img/footer/zakenman.png") no-repeat center bottom;
  }

  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 27px;
    }
  }

  .hero {
  }

  @media (max-width: @screen-sm-max) {
    .main-content .hero {
      background-position: center 75%;
    }
  }
}
