#banner-form {
  padding: 50px 0px 38px;
  text-align: center;
  background: #fafafa;

  @media (max-width: 768px) {
    padding: 25px 0 19px;
  }

  .cta {
    margin: 20px auto;
    position: relative;
    height: 46px;
    display: inline-block;

    @media (min-width: @screen-md) {
      margin-left: 30px;
    }

    .postcode {
      width: 155px;

      @media (min-width: @screen-md) {
        width: 225px;
      }
    }

    input {
      height: 100%;
      border: none;
      vertical-align: middle;
    }

    input[type="text"] {
      border: 1px solid #d9d9d9;
      border-right: none;
      box-shadow: none;
      color: #565a5c;
      &:focus {
        outline: 0;
        border-color: #024280;
        border-right: none;
      }
    }

    input[type="submit"] {
      color: #fff;
      font-weight: bold;
      padding: 0 30px;
      background: #1aad4a;

      @media(min-width: @screen-md) {
        padding: 0 50px;
        font-size: 24px;
      }

      &:hover {
        background: #038e37;
      }
      &:focus {
        outline: none;
        background: #038e37;
      }
    }
  }
}
