@import (reference) "../theme.less";

[data-site="giardinieri_24_it"] {

  .footer-wrap .footer-image {
    background-image: url("/bundles/sdb2bredesign/build/img/footer/gardener.png");
  }

  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 28px;
    }
  }

  .hero {
    background-position: 50% 12%;
  }
}
