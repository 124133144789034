@import '../theme.less';

.bullet-progress {
  text-align: center;
  margin: 5px;

  .bullet-container {
    text-align: center;
    display: inline-block;
  }

  .container {
    margin: 0 auto;
  }

  [class^="step-"] {
    display: inline-block;
    text-align: center;
    position: relative;
    font-size: 12px;

    &:last-child {
      margin-right: 0;
    }

    @media(min-width: @screen-xs) {
      font-size: 14px;
    }

    &:first-child {
      @media(min-width: @screen-md) {
        .bullet {
          margin-left: 0;
        }
      }
    }

    .bullet, .bullet-label {
      -webkit-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
      line-height: 1;
    }

    .bullet {
      border-radius: 15px;
      margin: 5px;
      font-size: 14px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #d8d8d8;
      background: white;
      display: inline-block;

      @media (min-width: @screen-sm) {
        border-radius: 20px;
        margin: 10px;
        font-size: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }

    @media (min-width: @screen-md) {
      font-size: 16px;
    }

    .arrow-container {
      display: inline-block;
      margin-left: 15px;
      padding-right: 15px;
      vertical-align: middle;

      @media(min-width: @screen-sm) {
        margin-bottom: 1px;
        position: relative;
      }
    }

    .arrow_enter {
      position: absolute;
      width: 20px;
      border-bottom: 2px solid #d9d9d9;
      background: transparent;
      top: 39%;
      left: 65px;

      @media (min-width: @screen-sm) {
        width: 30px;
      }

      @media (min-width: @screen-sm) {
        position: static;
        width: 40px;
      }
    }

    .arrow_enter:after {
      content: "";
      position: absolute;

      left: 20px;
      height: 0;
      width: 0;
      border-left: 10px solid #d9d9d9;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      top: -6px;

      @media (min-width: @screen-sm) {
        left: 40px;
        border-left: 11px solid #d9d9d9;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        top: -7px;
      }
    }

    .bullet-label {
      @media (min-width: @screen-sm) {
        display: inline-block;
      }
    }

    &.active, &.done {
      .bullet, .bullet-label {
        color: #17ac49;
        border-color: #17ac49;
        font-weight: bold;
      }
    }

    &.done {

      .arrow_enter:after {
        border-left-color: #17ac49;
      }

      .arrow_enter {
        border-color: #17ac49;
      }

      .bullet {
        span {
          display: none;
        }

        &:after {
          content: '✓';
        }
      }
    }
  }
}

[data-locale="nl_NL"], [data-locale="nl_BE"] {
  .step-1 .arrow_enter {
    left: 54px;
  }
}

[data-locale="nl_NL"], [data-locale="nl_BE"] {
  .step-2 .arrow_enter {
    left: 75px;
  }
}

 [data-locale="sv_SE"] {
  .step-1 .arrow_enter {
    left: 40px;
  }
}
 [data-locale="sv_SE"] {
  .step-2 .arrow_enter {
    left: 55px;
  }
}
