@font-face {
  font-family: "ProximaNova";
  src:url("../fonts/proxima-nova/ProximaNova-RegularWeb.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src:url("../fonts/proxima-nova/ProximaNova-BoldWeb.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

