.btn.btn-primary {
  color: #fff;
  padding: 8px 32px;
  border: none;
  background-color: @primary-color;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, .1) inset;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:active:focus {
  background-color: @primary-color-dark;
}

.btn.btn-secondary {
  border: 1.5px solid @primary-color;
  color: @primary-color;
}

.btn.btn-secondary:hover {
  background-color: @gray-200;
}

.image-button {
  padding: 20px 16px;

  img {
    display: block;
    margin: 0 auto 8px;
  }
}

.copy-button {
  .disabled-state-translation {
    display: none;
  }

  &.copied {
    .initial-state-translation {
      display: none;
    }
    .disabled-state-translation {
      display: inline-block;
    }
  }
}

// "#cookiebanner a.c-button.btn-primary" and "#cookiebanner a.s-button" correct buttons and links styling for cookiebot
#cookiebanner a.c-button.btn-primary {
  border-radius: 0;
  border: 1px solid #1ba94a;
  background-color: #1ba94a;
}

#cookiebanner a.s-button {
  color: #1ba94a;
}

.btn {
  white-space: normal;
  font-weight: 700;
  line-height: 28px;
}

.sticky-button-panel {
  @media (max-width: 768px) {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 6;
    width: 100%;
    padding: 8px 16px;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .08), 0 0 14px 0 rgba(0, 0, 0, .16);
  }
}


// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
a.btn[disabled],
fieldset:disabled a.btn {
  pointer-events: none;
}
//