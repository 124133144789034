@import (reference) "../theme.less";

[data-site="schuifpui_offertes_nl"] {

  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 28px;
    }
  }

  .hero {

    @media (max-width: @screen-xs-max) {
      background-position: center 70%;
    }
  }
}
