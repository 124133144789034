@import (reference) "../theme.less";

[data-site="calefaccion24_es"] {

  .footer-image {
    background: url("/build/img/footer/handyman.jpg") no-repeat;
  }

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 31px;
      }
    }

    /**IE 8 **/
    background-size: cover;
  }

  @media (max-width: @screen-xs-max) {
    .main-content::before {
      background-size: cover;
    }
  }
}

