@import "../variables.less";

/** :root makes sure that this will only be applied to IE8+ **/
:root {
  [type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .radio-inline {
    padding-left: 0;

    .icon-holder {
      display:inline-block;
      min-width: 16px;
    }
  }

  .radio {
    @media (max-width: 768px) {
      padding: 0;
      margin-bottom: 4px;
      border: 1px solid @gray-300;
      border-radius: 4px;
      &:has(input[type="radio"].ng-valid-parse) {
        border-color: @primary-color;
        border-width: 2px;
        label {
          padding: 9px 16px 9px 12px;
        }
      }
      label {
        margin-bottom: 0;
        line-height: 22px;
        padding: 10px 16px 10px 13px;
      }

    }
    @media (min-width: 768px) {
      padding: 0 0 1px 0;
      &:hover {
        border-radius: 9px;
        padding-left: 8px;
        margin-left: -8px;
      }
      >label {
        min-height: 35px;
        padding-top: 6px;
        margin-bottom: 0;
      }
    }
    &:hover {
      background-color: @gray-100;
    }
    &:has(input[type="radio"].ng-invalid) {
      .radio-label {
        color: @gray-600;
      }
    }

  }

  /* One radio button per line */
  .radio label {
    display: block;
    cursor: pointer;
  }

  [type="radio"] + span {
    display: block;
  }

  /* the basic, unchecked style */
  [type="radio"] + span:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: -2px;
    border-radius: 7.5px;
    border: 3px solid #fff;
    box-shadow: 0 0 0 2px @gray-300;
    margin-right: 5px;
    transition: 0.2s ease all;
    background: #fff;
  }

  /* the checked style using the :checked pseudo class */
  [type="radio"]:checked + span:before {
    background: @primary-color;
    box-shadow: 0 0 0 2px @primary-color;
  }

  [type="radio"]:checked + span {
    font-weight: 700;
    color: @gray-700;
  }


  /* Class to keep the label aligned when the text breaks in two lines or more */
  .radio-label {
    padding-left:2em;
    text-indent:-24px;
    margin-left: -5px;

    .glyphicon {
      display: none;
    }

    .radio-sublabel {
      display: block;
      margin-top: 4px;
      text-indent: 0;
      font-size: 12px;
      line-height: 1.33;
      color: @gray-500;
    }
  }
}
