@import 'theme.less';

.hamburger-button {
  width: 29px;
  cursor: pointer;
  margin: 6px 0;
  
  .patty {
    border: 2px solid #585857;
    margin-top: 4px;
  }
}

.hamburger-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: 250ms ease-in-out opacity;
  transition: 250ms ease-in-out opacity;
  z-index: 2;

  &.closed {
    display: none;
  }
}

.hamburger-menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 400px;
  box-shadow: 0 0 40px rgba(0,0,0,0.25);
  padding-top: 70px;
  z-index: 102; // 1 more than the header
  zoom: 1;

  @media(max-width: @screen-sm-max) {
    max-width: 95%;
    overflow: scroll;
  }

  -webkit-transition: 250ms ease-in-out right;
  transition: 250ms ease-in-out right;

  background-color: #ffffff;

  cursor: pointer;

  &.closed {
    right: -400px;
  }

  .option-close {
    font-weight: bold;
    display: block;
    width: 32px;
    height: 32px;
    padding-left: 10px;
    line-height: 35px;
    border-radius: 24px;
    font-size: 26px;
    background: #f5f5f5;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  a {
    text-decoration: none;
    color: #5e5f61;

    &.menu-item {
      display: flex;
      padding-left: 40px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 8px;
      font-size: 18px;

      svg {
        margin-right: 8px;
        color: @primary-color;
      }

      &:hover {
        background-color: @primary-color;
        color: @white;

        svg {
          color: @white;
        }
      }
    }
  }

  .push-down {
    margin: auto 40px 40px 40px;
  }

  .btn {
    white-space: normal;
  }

  .option {
    padding: 26px 50px;
    font-size: 18px;
    line-height: 18px;

    border-bottom: 1px solid #f7f7f7;
    position: relative;

    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;

    .icon-container {
      float: left;
      text-align: center;
      width: 30px;
      margin-right: 10px;
    }

    svg {
      color: #1aad4a;
      height: 20px;
      width: 30px;
    }

    &:hover, &:active, &:focus {
      background-color: #1aad4a;
      color: #ffffff;
      font-size: 20px;
      padding-left: 22px;

      svg {
        color: #ffffff;
      }

      a {
        text-decoration: none;
        color: #ffffff;
      }

    }
  }
}
