.hamburger-button {
  width: 29px;
  cursor: pointer;
  margin: 6px 0;

  .patty {
    border: 2px solid #585857;
    margin-top: 4px;
  }
}

.hamburger-menu-drawer {
  @width: 350px;
  @shadow: 35px;

  position: fixed;
  width: @width;
  top: 0;
  bottom: 0;
  right: -@width - @shadow;
  transition: right 500ms ease-in-out;

  background: #ffffff;
  z-index: 9999;
  box-shadow: 0 0 @shadow rgba(0,0,0,0.25);
  overflow-y: scroll;

  &.open {
    right: 0;
  }

  a {
    color: #585857;
    &:hover {
      text-decoration: none;
    }
  }

  & > div { // container
    min-width: 300px;
    margin-top: 65px;

    & > div { // top level
      font-weight: bold;
      font-size: 18px;
      padding: 28px 50px;
      border-bottom: 2px solid #f8f8f8;

      transition: font-size 100ms ease-in-out;

      &:hover {
        background: #fdfdfd;
        font-size: 20px;
      }

      & > div {
        max-height: 0;
        overflow: hidden;
        transition: max-height 250ms ease-in-out;

        &.open { // not really smooth, but transition to height: auto is tricky without JS
          max-height: 900px;
        }

        & > div > a { // sub level
          padding: 20px 10px;
          font-size: 16px;
          font-weight: normal;
          display: block;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .hamburger-close-button {
    margin: 20px;
    width: 32px;
    height: 32px;
    display: inline-block;
    cursor: pointer;
    background: transparent 0 0 no-repeat url('/build/img/seo-components/x.png');
    float: right;
  }
}

.hamburger-mask {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}
