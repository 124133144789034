@import "../variables.less";

.radio-boxed {
  label {
    cursor: pointer;
    display: inline-block;
    width: 42px;
    height: 44px;
    border-radius: 4px;
    border: 1px solid @gray-300;
    font-weight: normal;
    text-align: center;
    padding: 11px 0;

    &:hover {
      background-color: @gray-100;
    }
  }

  input[type="radio"]:checked + label {
    border: solid 2px @primary-color;
    font-weight: bold;
  }

  .glyphicon-radio-boxed {
    display: none;
  }
}


