@import (reference) "../theme.less";

[data-site="vochtbestrijdingoffertes_com"] {
  .hero {
  }
  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 27px;
    }
    h2 {
      font-size: 20px;
    }
  }
 }
