@import (reference) "../theme.less";

[data-site="vloeroffertes_com"] {

  .footer-image {
    background: url("/build/img/footer/handyman.jpg") no-repeat;
  }

  .hero {
  }
 }
