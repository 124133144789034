@import (reference) "../theme.less";

[data-site="ongediertebestrijdingoffertes_com"] {
  .footer-image {
    background-image: url("/build/img/footer/exterminator.jpg");
  }

  .hero {
  }

  @media (max-width: @screen-sm-max) {
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 16px;
    }

    .about-us {
      .hero {
        background-position: center -276px;
      }
    }
  }
}
