@import (reference) "../theme.less";

[data-site="aireacondicionado365_es"] {
  .hero {
    /**IE 8 **/
    background-size: cover;
  }

  @media (max-width: @screen-xs-max) {
    .main-content::before {
      background-size: cover;
    }
    h1 {
      font-size: 23px;
    }
    .step-1 {
      width: 95px;
    }
  }
}
