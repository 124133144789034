@import (reference) "../theme.less";

[data-site="riscaldamento365_it"] {

  .footer-image {
    background: url("/build/img/footer/handyman.jpg") no-repeat;
  }

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 20px;
      }
    }

  }

}

