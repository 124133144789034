.clearable {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23364247' fill-rule='nonzero' d='M8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854a.5.5 0 0 1 .708-.708L8 7.293z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right -50px center;
}

.clearable.x {
  background-position: right 10px center;
}

.clearable.cursor-pointer {
  cursor: pointer;
}
