@import (reference) "../theme.less";

[data-site="allarmi_24_it"] {

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 28px;
      }
    }

  }
 }
