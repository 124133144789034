.btn-arrow-tail {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::after {
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    content: "";
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Ebutton-arrow-tail%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='button-arrow-tail-copy' transform='translate(-107.000000, -14.000000)'%3E%3Cg id='Group-2' transform='translate(77.000000, 8.000000)'%3E%3Cg id='Group' transform='translate(30.000000, 4.000000)'%3E%3Crect id='Rectangle' x='0' y='0' width='16' height='20'%3E%3C/rect%3E%3Cpath d='M8,4 L14,10 L8,16 M0,10 L14,10' id='Path-2' stroke='%23FFFFFF' stroke-width='3'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
  }
}
.btn-wizard-wrap {
  a {
    font-size: 16px;
  }
}

