@import (reference) "../theme.less";

[data-site="airco_offertes_nl"] {
  .hero {
    /**IE 8 **/
    background-size: cover;
  }

  @media (max-width: @screen-xs-max) {
    .main-content::before {
      background-size: cover;
    }
  }
}
