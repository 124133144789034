@import (reference) "../theme.less";

[data-site="ventanas_365_es"] {

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 23px;
      }
    }

  }

  @media (max-width: @screen-xs-max) {
    .step-1 {
      width: 95px;
    }
  }

}
