#section-logos {
  h4 {
    font-size: 15px;
    margin-top: 10px;

    @media(min-width: @screen-xs) {
      font-size: 18px;
      margin: 20px 0;
    }
  }

  .logos {
    @media(min-width: @screen-xs) {
      margin-top: 20px;
    }

    .logo {
      background-size: contain;
      padding: 0 10px;
      max-height: 45px;
      max-width: 100%;
      object-fit: contain;
    }

    .logo-container {
      display: none;

      //  Display 3 logos on xs devices
      &:nth-child(-n+3) {
        display: inline-block;
        max-width: 33%;
      }

      // Display 4 logos on small devices
      @media (min-width: @screen-sm) {
        &:nth-child(-n+4) {
          display: inline-block;
          max-width: 25%;
        }
      }

      // Display 6 logos on small devices
      @media (min-width: @screen-md) {
        display: inline-block;
        max-width: 10%;
      }
    }
  }
}
