.company-review {
  text-align: center;

  @media (max-width: @screen-sm) {
    .container {
      padding: 0 8px;
    }
  }

  @media (min-width: @screen-sm) {
    .container {
      padding: 40px 0;
    }
  }


  h2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .company-review-component {
    padding: 0 13px;
    text-align: left;
    margin-bottom: 16px;
    .card-body {
      border-radius: 4px;
      border: 1px solid #d7d7d7;
      padding: 16px;
      font-size: 16px;
      .rating-box {
        height : 28px;
        line-height: 28px;
        margin-left: auto;
      }
      .text-truncate {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: normal;
      }

    }
  }

}