@import (reference) "../theme.less";

[data-site="shutteroffertes_nl"] {

  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 31px;
      }
    }

  }

}

