.file-upload {
  .uploaded-files {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    & > .uploaded-file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #9d9999;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      .upload-file-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .upload-file-loader {
        flex-shrink: 0;
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath fill='%23d9d9d9' d='M20,35 C11.7157288,35 5,28.2842712 5,20 C5,11.7157288 11.7157288,5 20,5 C28.2842712,5 35,11.7157288 35,20 C35,28.2842712 28.2842712,35 20,35 Z M20,33 C27.1797017,33 33,27.1797017 33,20 C33,12.8202983 27.1797017,7 20,7 C12.8202983,7 7,12.8202983 7,20 C7,27.1797017 12.8202983,33 20,33 Z'/%3E%3Cpath fill='%231aad4a' d='M29.1923882,10.8076118 C26.8398509,8.45507456 23.5898509,7 20,7 L20,5 C24.1421356,5 27.8921356,6.67893219 30.6066017,9.39339828 L29.1923882,10.8076118 Z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.7s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        width: 24px;
        height: 24px;
      }

      .upload-file-delete {
        flex-shrink: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' fill-rule='nonzero' stroke='%23979797' stroke-linecap='round' stroke-linejoin='round' d='M16.5 5.5h4-17 4l2-3h5l2 3zm-8 4v8-8zm7 0v8-8zm-3.5 0v8-8zm-6.5-4v14a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-14'/%3E%3C/svg%3E%0A");
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .upload-files-template {
      display: none;
    }
  }

  .file-input {
    display: flex;
    flex-direction: column;
    input[type=file] {
      display: none;
    }

    .file-browse {
      &:disabled {
        color: #9e9e9e;
        background: #ededed;
      }

      padding: 9px 16px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.14;
      color: #17aa54;
    }
  }

  .file-sub-label {
    margin-top: 3px;
    font-weight: 400;
    font-size: 14px;
    color: #999999;

    &.error {
      color: #a94442;
    }
  }
}
