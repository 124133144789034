@import "../variables.less";

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-start {
  text-align: left !important;
}

.text-dark {
  color: #000000;
}

.text-gray-500 {
  color: @home-gray-500 !important;
}

.p-small {
  font-size: 14px;
}
