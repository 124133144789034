@import (reference) "../theme.less";

[data-site="meubelmakeroffertes_nl"] {
  .hero {
  }

  @media (max-width: @screen-sm-max) {
    h1 {
      font-size: 28px;
    }
  }
 }
