[uib-datepicker-popup-wrap] {
  .glyphicon-chevron-left, .glyphicon-chevron-right {
    color: #36B760;
  }

  table {
    outline: none;

    thead tr:first-child {
      button {
        color: #000;
      }
    }

    thead tr + tr {
      border-top: 1px solid #c2c2c2;
      border-bottom: 2px solid #e0e0e0;

      th:last-child {
        padding-right: 15px;
      }

      th {
        font-weight: normal;
        color: #53c177;
      }

      th + th + th + th + th + th + th, &th:last-child {
        color: black;
      }
    }
  }

  button {
    color: #4a4a4a;

    .text-muted {
      color: #b3b3b3;
    }
    border: none;
  }

  .text-info {
    color: #36B760;
  }

  .btn-info.active,
  .selected .btn.btn-default,
  .selected .btn.btn-info {
    .text-info {
      color: #FFF;
    }
    background-color: #19ad4a;
    &:hover {
      background-color: #19954c;
    }
    color: #fff;
    border-radius: 0;

    .text-muted {
      color: #fff;
    }
  }

  .selected-light .btn.btn-default,
  .selected-light .btn.btn-info {
    background-color: #d1efdb;
    color: #19ad4a;

    &:hover {
      background-color: #c9e6d3;
      color: #19954c;
    }
    border-radius: 0;
    .text-muted {
      color: #19ad4a;
    }
  }

  //Workaround for date-picker issue
  //https://github.com/angular-ui/bootstrap/issues/6534
  //https://github.com/angular-ui/bootstrap/issues/5446
  .uib-day.ng-leave-prepare {
    display: none !important;
  }
}

.panel-warning {
  background-color: #f5f5f5;
  padding: 20px;
  font-size: 14px;
}

.datepicker-hidden {
  .uib-datepicker-popup {
    display: none !important;
  }
}

.datepicker-input {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23999' fill-rule='nonzero' d='M17 8H3v9h14V8zM3 7h14V4h-1V3h1a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1v1H3v3zm4-3V3h6v1H7zM5 1.5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4zm9 0a.5.5 0 1 1 1 0v4a.5.5 0 1 1-1 0v-4z'/%3E%3C/svg%3E%0A") no-repeat;
  background-position: left 6px center;
  background-color: white !important;
  &:disabled {
    background-color: #eeeeee !important;
  }
  padding-left: 29px !important;
  padding-right: 0 !important;

  @media (max-width: @screen-md) {
    padding-left: 27px;
  }

  @media (max-width: 320px) {
    background: none;
    padding-left: 12px !important;
  }
}

.dropdown-menu {
  padding: 10px 12px;
}

input[type='date'] {
  -webkit-appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23999' fill-rule='nonzero' d='M17 8H3v9h14V8zM3 7h14V4h-1V3h1a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1v1H3v3zm4-3V3h6v1H7zM5 1.5a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0v-4zm9 0a.5.5 0 1 1 1 0v4a.5.5 0 1 1-1 0v-4z'/%3E%3C/svg%3E%0A") no-repeat;
  background-position: left 9px center;
  padding-left: 35px;
  line-height: 1.63 !important;
}

.datepicker-group {
  .native-datepicker {
    display: inline-flex;
    width: 50%;
    margin: 0px -2px;
  }
}
