@list-group-check-mark-padding-left: 16px;
@list-group-check-mark-icon-width: 20px;
@list-group-check-mark-icon-height: @list-group-check-mark-icon-width;
@list-group-check-mark-font-size-mobile: 16px;
@list-group-check-mark-font-size-desktop: 18px;
@list-group-check-mark-line-height-mobile: 1.5;
@list-group-check-mark-item-margin-bottom: 4px;
@list-group-check-mark-line-height-desktop: 1.44444444;

.list-group-check {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style: none;
  .list-group-check-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-left: calc(@list-group-check-mark-padding-left + @list-group-check-mark-icon-width);
    margin-bottom: 0;
    font-size: @list-group-check-mark-font-size-mobile;
    line-height: @list-group-check-mark-line-height-mobile;
    border: none;
    &::before {
      position: absolute;
      top: calc((@list-group-check-mark-font-size-mobile * @list-group-check-mark-line-height-mobile - @list-group-check-mark-icon-height) / 2);
      left: 0;
      display: inline-block;
      width: @list-group-check-mark-icon-width;
      height: @list-group-check-mark-icon-height;
      content: "";
      border: none;
    }
    @media (min-width: @screen-md) {
      font-size: @list-group-check-mark-font-size-desktop;
      line-height: @list-group-check-mark-line-height-desktop;
      &::before {
        top: calc((@list-group-check-mark-font-size-desktop * @list-group-check-mark-line-height-desktop - @list-group-check-mark-icon-height) / 2);
      }
    }
    &.list-group-check-item-primary::before {
      background: url("@{custom-icon-check-primary}") no-repeat;
      background-size: @list-group-check-mark-icon-width @list-group-check-mark-icon-height;
    }
    &.list-group-check-item-gray-500::before {
      background: url("@{custom-icon-check-gray}") no-repeat;
      background-size: @list-group-check-mark-icon-width @list-group-check-mark-icon-height;
    }
    &:not(:last-of-type) {
      margin-bottom: @list-group-check-mark-item-margin-bottom;
    }
  }
}
