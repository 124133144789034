.contact {
  .contact-form {
    margin-bottom: 40px;
  }
}

.contact-row {
  margin-top: 50px;
  border-top: 1px solid #e2e2e2;
}
