@import (reference) "../theme.less";

[data-site="stratenmakeroffertes_nl"] {

  @media (max-width: @screen-xs-max) {
    h1 {
      font-size: 28px;
    }
  }

  .hero {
  }
}
