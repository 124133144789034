@import 'theme.less';
@import 'mixins.less';
@import 'variables.less';

.content {

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    margin-top: 0;
  }

  > .row {
    padding: 20px;

    @media (min-width: 768px) {
      padding: 56px 20px;
    }
  }
}

.why {
  text-align: center;

  &.why-trust {
    svg {
      height: 48px;
    }

    .row-eq-height {
      font-size: 16px;
    }

    @media (max-width: @screen-sm) {
      .row {
        padding: 20px 0 8px 0;

        .row-eq-height {
          padding: 0;

          > div {
            padding: 0 16px;

            p {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  svg {
    height: 72px;
  }
}

.why-new {
  margin-bottom: 16px;
  font-size: 16px;

  svg {
    height: 25%;
  }

  h2 {
    line-height: 1.25;
  }

  .container {
    max-width: 1200px !important;
    padding: 0 4px;
  }

  @media (min-width: 768px) {
    margin: 40px 0 !important;

    .container {
      padding: 40px;
      border: 1px solid @home-gray-200;
      border-radius: 4px;
    }
  }
}

// HOME-3857 ultimate USP and Google review rating experiment
.why-usp-new {
  margin-bottom: 16px;

  &.home_b2c_ultimate_usp_and_review_rating_experiment__c {
    margin-bottom: 0;
    .container {
      border: none;
      margin: 0 auto !important;
    }
    @media (max-width: @screen-sm) {
      padding: 0 13px;
    }
  }

  .container {
    border-radius: 4px;
    border: 1px solid @home-gray-200;
    padding: 32px;
    width: auto;
    margin: 0 93px;

    .icon-wrapper {
      border-radius: 8px;
      padding: 8px;
      background: @home-blue-light;
      width: 56px;
      height: 56px;
    }

    .labels-wrapper {
      line-height: 22px;
      padding: 6px 0;

      > h5 {
        font-size: 18px;
        color: @home-gray-600
      }

      > p {
        font-size: 16px;
        color: @home-gray-500;
      }
    }
  }

  @media (min-width: @screen-md) and (max-width: @screen-md-max) {
    .container {
      margin: 0 80px;
    }
  }

  @media (min-width: @screen-sm) and (max-width: @screen-sm-max) {
    .container {
      width: auto;
      max-width: 720px;
      margin: 40px auto 0 auto;
      box-sizing: content-box;
      padding: 0;

      > div {
        padding: 0;
        margin: 32px
      }
    }

  }

  @media (max-width: @screen-sm) {
    padding: 0 24px;
    .container {
      max-width: 560px;
      padding: 0;
      margin: 16px auto;

      > div {
        margin: 16px;
        padding: 0;
      }

      .icon-wrapper {
        padding: 4px;
        width: 32px;
        height: 32px;

        > svg {
          width: auto;
          height: auto;
        }
      }

      .labels-wrapper {
        line-height: 20px;
        margin: 0;
        padding: 0;

        > h5 {
          font-size: 16px;
        }

        > p {
          font-size: 14px;
        }
      }

    }
  }
}
// endregion

#legal_information {
  padding: 15px 0;
}

.directory-city {
  ul {
    list-style: none;
    margin: 0;
    margin-top: 30px;
    padding: 0;

    li {
      font-size: 19px;
      line-height: 30px;
    }
  }
}

#company-logos {
  h3 {
    margin-bottom: 30px;
  }

  .row& {
    padding: 40px 10px;
  }

  img {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;

      @media (min-width: @screen-lg) {
        margin-right: 0;
      }
    }

    @media (min-width: @screen-lg) {
      margin-right: 15px;
      margin-bottom: 0;
      display: inline-block;
    }
  }
}

.directory-region {
  font-size: 16px;
  //background: url('/build/img/directory.jpg') no-repeat center center;
  background-size: cover;

  .slab {
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 50px;
    background: rgba(255, 255, 255, 0.90);

    button {
      padding: 8px 16px;
      background: transparent;
    }
  }
}

.reviews {
  text-align: center;

  h2 {
    margin-bottom: 50px;
  }

  .review {
    border: 2px solid #f9f9f9;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    padding: 42px;
    min-height: 365px;

    .sub {
      color: #95969b;
    }

    .portrait {
      .holder {
        margin: 0 auto;
        border-radius: 110px;
        width: 110px;
        overflow: hidden;
      }

      img {
        width: 110px;
      }
    }

    &.center {
      border: 1px solid #eaeaea;
    }
  }
}

.tips {
  background: #fafafa;
  padding-bottom: 100px;

  .blocks {
    background: #ffffff;
    border: 1px solid #f4f4f4;
    padding: 0;

    div {
      text-transform: uppercase;
      font-size: 18px;
      text-align: center;
      border: 1px solid #efefef;
      padding: 100px 10px;

      img {
        height: 60px;
      }

      p {
        margin-top: 10px;
      }
    }
  }

  .row {
    padding-bottom: 0;
  }
}

.ribbon {

  h2 {
    margin-bottom: 20px;

    @media (min-width: @screen-md) {
      display: inline-block;
      vertical-align: middle;
      margin: 0 40px 0 0;
    }
  }

  .button {
    display: inline-block;
    font-size: 17px;
    padding: 14px 50px;
  }
}

.logo-bar-responsive {
  .row-logo-xs {
    @media (min-width: @screen-sm) {
      &:last-child {
        .display-flex() !important; // To fix issue in original implementation
      }
    }
  }

  h3 {
    margin-bottom: 30px;
  }

  padding: 40px 0;
  max-width: 400px;
  margin: 0 auto;

  @media (min-width: @screen-sm) {
    max-width: none;

    .row-logo-xs {
      width: auto;
    }
  }

  .row-logo-xs {
    margin-bottom: 10px;
    place-content: center;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: @screen-sm) {
      margin-bottom: 0;
    }
  }

  .row-logo-xs {
    .display-flex();
    align-items: center;

    @media (min-width: @screen-sm) {
      &:last-child {
        display: none;
      }
    }

    @media (min-width: @screen-lg) {
      &:first-child .logo-container:first-child .logo {
        padding-left: 0;
      }

      &:last-child {
        .display-flex();

        .logo-container:last-child .logo {
          padding-right: 0;
        }
      }
    }
  }

  @media (min-width: @screen-sm) {
    .row-logo-sm {
      .display-flex();
      justify-content: center;
      align-items: center;
    }
  }

  img {
    margin: 0 auto;
    max-height: 45px;
    padding: 0 5px;

    @media (min-width: @screen-sm) {
      padding: 0 10px;
      max-width: 105px;
    }

    @media (min-width: @screen-md) {
      padding: 0 15px;
      max-width: 130px;
    }

    @media (min-width: @screen-lg) {
      padding: 0 20px;
      max-width: 122px;
    }
  }

  .no-flexbox & {
    .row-logo-sm, .row-logo-xs, .row-logo-xs:last-child {
      display: inline-block;
    }
  }

  .flexboxtweener & {
    .row-logo-xs, .row-logo-xs:last-child {
      .display-flex();
    }
  }
}

// endregion

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0.0)
  }
  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.trust-why {
  text-align: center;
  display: block;

  h2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  svg {
    @media (min-width: @screen-sm) {
      height: 128px;
    }

    text {
      font-size: 15px;
      fill: #5e5f61;
      //
    }
  }

  .container.content {
    //width: 100%;

    .trust-components {
      padding-right: 0;
      display: block;

      .trust-component {
        display: block;
      }
    }
  }
}

@media (max-width: @screen-md) {
  .trust-why .container.content .trust-components {
    padding-left: 0;
  }
}

.trust-reviews {
  text-align: center;
  display: block;

  h2 {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  h3 {
    color: #00af1a;
  }

  p {
    font-size: 15px;

    &.signature {
      text-align: center;
    }
  }

  .container.content {
    .trust-trust-component {
      padding-top: 25px;
      padding-left: 100px;
      padding-right: 100px;
      display: block;


    }
  }
}
