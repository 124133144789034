@import '../theme.less';
@import '../variables.less';

.pretty-checkbox {
  display: inline-block;
}

.has-error .pretty-checkbox,
.has-error .pretty-checkbox-inline,
.has-error .pretty-checkbox-container,
.has-error.pretty-checkbox label,
.has-error.pretty-checkbox-inline label,
.has-error.pretty-checkbox-container label {
  color: @red;
}

/** :root makes sure that this will only be applied to IE8+ **/
:root {
  .pretty-checkbox-container {
    &:has(input[type="checkbox"].ng-not-empty) {
      label {
        font-weight: 700;
      }
    }

    &:has(.other-checkbox-container) {
      position: relative;
      margin-bottom: 48px;
    }


    @media (max-width: 768px) {
      &:has(input[type="checkbox"].ng-not-empty) {
        border-color: @primary-color;
        border-width: 2px;
        >label {
          padding: 9px 0 !important;
        }
      }
      >label {
        padding: 10px 1px;
        line-height: 22px;
      }
      .pretty-checkbox {
        margin-top: 13px;
      }

      margin-bottom: 4px;
      padding: 0 15px;
      border: 1px solid @gray-300;
      border-radius: 4px;
    }

    @media(min-width: 768px) {
      &:hover {
        background-color: @gray-100;
        border-radius: 9px;
        padding-left: 8px;
        margin-left: -8px;
        .other-checkbox-container {
          left: 23px
        }
      }
      >label {
        padding: 5px 0;
      }
      .pretty-checkbox {
        margin-top: 7px;
      }
    }

    label {
      width:80%;
      cursor: pointer;
      margin-left: 9px;
      margin-bottom: 0;
      font-weight: normal;
    }

    .pretty-checkbox {
      width: 18px;
      height: 18px;
      position: relative;
      float: left;

      input[type=checkbox] {
        visibility: hidden;
      }
    }

    .pretty-checkbox label.mask {
      cursor: pointer;
      position: absolute;
      width: 18px;
      height: 18px;
      top: 0;
      left: 0;
      background: white;
      border: 2px solid @gray-300;
      border-radius: 4px;
      margin: 0;

      -webkit-transition: 200ms ease-in-out background, 200ms ease-in-out border-color;
      transition: 200ms ease-in-out background, 200ms ease-in-out border-color;
    }

    .pretty-checkbox label.mask:after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 13px;
      height: 7px;
      background: transparent;
      top: 2px;
      left: 0;
      border: 3px solid white;
      border-top: none;
      border-right: none;
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .pretty-checkbox input[type="checkbox"]:checked {
        + label.mask {
            border: none;
            background: @primary-color;
        }
    }

    .pretty-checkbox input[type="checkbox"]:checked + label.mask:after {
      opacity: 1;
      border-color: white;
      background: @primary-color;
      width: 12px;
      top: 4px;
      left: 3px;
    }
  }
}
