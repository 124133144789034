@import (reference) "../theme.less";

[data-site="zonnepaneeloffertes_com"] {

  .footer-image {
    background: url("/build/img/footer/solarman.jpg") no-repeat;
  }


  .hero {

    @media (max-width: @screen-xs-max) {
      h1 {
        font-size: 24px;
      }
    }
    background-position: 50% 20%;
  }

  @media (max-width: @screen-xs) {
    .why h2 {
      font-size: 21px;
    }
  }
}
